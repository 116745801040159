// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-arugula-and-wintercress-js": () => import("./../../../src/pages/arugula-and-wintercress.js" /* webpackChunkName: "component---src-pages-arugula-and-wintercress-js" */),
  "component---src-pages-black-locust-js": () => import("./../../../src/pages/black-locust.js" /* webpackChunkName: "component---src-pages-black-locust-js" */),
  "component---src-pages-burdock-js": () => import("./../../../src/pages/burdock.js" /* webpackChunkName: "component---src-pages-burdock-js" */),
  "component---src-pages-cherry-plum-js": () => import("./../../../src/pages/cherry-plum.js" /* webpackChunkName: "component---src-pages-cherry-plum-js" */),
  "component---src-pages-dandelion-js": () => import("./../../../src/pages/dandelion.js" /* webpackChunkName: "component---src-pages-dandelion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mint-and-juniper-js": () => import("./../../../src/pages/mint-and-juniper.js" /* webpackChunkName: "component---src-pages-mint-and-juniper-js" */),
  "component---src-pages-mulberry-js": () => import("./../../../src/pages/mulberry.js" /* webpackChunkName: "component---src-pages-mulberry-js" */),
  "component---src-pages-nettle-js": () => import("./../../../src/pages/nettle.js" /* webpackChunkName: "component---src-pages-nettle-js" */),
  "component---src-pages-oregano-js": () => import("./../../../src/pages/oregano.js" /* webpackChunkName: "component---src-pages-oregano-js" */),
  "component---src-pages-saltbush-js": () => import("./../../../src/pages/saltbush.js" /* webpackChunkName: "component---src-pages-saltbush-js" */)
}

